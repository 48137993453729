'use strict';

var GoogleFonts = function () {
  var WebFont = require('webfontloader');
  WebFont.load({
    google: {
      families: [
        'Muli:700,800',
        'Lato:400,400i,600,700,700i'
        ]
    }
  });
}
module.exports = GoogleFonts;
