'use strict';

// var $ = require('jquery');

var mobileNav = function () {

  function isMobileDevice() {
      return (typeof window.orientation !== "undefined") || (navigator.userAgent.indexOf('IEMobile') !== -1);
  };

  var button = document.getElementById('js-nav-toggle');

  // hamburger button feature
  button.onclick = function() {
    var navigation = document.getElementById('js-navigation');
    this.classList.toggle("is-active");
    navigation.classList.toggle("c-main-nav--is-expanded");
  };

  // make dropdowns open on mobileNav
  var buttons = document.getElementsByClassName('c-main-nav__btn');
  [].forEach.call(buttons, function (el) {
    var dropdown = el.nextElementSibling;
    el.onclick = function() {
      el.classList.toggle('c-main-nav__btn--is-clicked');
      dropdown.classList.toggle('is-expanded');
    }
  });
};

module.exports = mobileNav;
