'use strict';

var filterableSelect = function () {
  var $ = require('jquery'),
      select2 = require('select2'),
      Selectr = require('mobius1-selectr');


  $(document).ready(function() {
    //$('.js-select').select2();
  });

  // apply select to all selects
  var selects = document.getElementsByClassName('js-select');
  [].forEach.call(selects, function (element) {
    var s = new Selectr(element);
  });


};

module.exports = filterableSelect;
