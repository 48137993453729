'use strict';

var $ = require('jquery');
window.jQuery = window.$ = $; // You expose jQ as a global object
var matchHeight = require('jquery-match-height');
require('jquery-debouncedresize');

var breakpoint = {};

breakpoint.refreshValue = function () {
  this.value = window.getComputedStyle(document.querySelector('body'), ':before').getPropertyValue('content').replace(/\"/g, '');
};
breakpoint.refreshValue();

var heights = function () {

  var matched = false;
  var columns = $('.js-height');

  //console.log(breakpoint.value);
  if ( breakpoint.value != 'xs' && breakpoint.value != 'sm' ) {
    columns.matchHeight({
       //byRow: false
    });
    matched = true;
  }

  $(window).on('debouncedresize', function(){
    breakpoint.refreshValue();
    //console.log(breakpoint.value);
    if( breakpoint.value != 'xs' && matched ) {
      // update matchheight
      $.fn.matchHeight._update();
    } else if ( breakpoint.value == 'lg' || breakpoint.value == 'xl' || breakpoint.value == 'md') {
      // re run the plugin or run for the first time if matched is not set
      columns.matchHeight({
         //byRow: false
      });
    } else {
      // remove matchheight
      columns.matchHeight({ remove: true });
      matched = false;
    }
  });

};

module.exports = heights;
