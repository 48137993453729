'use strict';

var externalLinks = function () {

  var links = document.links,
      host = location.hostname;

  var clicked = function(link) {
    if( link.getAttribute('href') && host !== link.hostname ) {
      link.target = "_blank";
    }
  };
  // attach above function to every a on click
  for (var x in links) {
    if(typeof links[x] == 'object') {
      links[x].onclick = clicked(links[x]);
    }
  }
}

module.exports = externalLinks;
