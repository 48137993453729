'use strict';

var modals = function () {
  const rodo = document.getElementById("js-rodo");
  if (rodo) {

    var tingle = require('tingle.js');
    var modal = new tingle.modal({
        footer: true,
        stickyFooter: true,
        closeMethods: ['escape'],
        closeLabel: "Close",
        cssClass: ['c-Rodo'],
        onOpen: function() {
            //console.log('modal open');
        },
        onClose: function() {
            //console.log('modal closed');
        },
        beforeClose: function() {
            // here's goes some logic
            // e.g. save content before closing the modal
            return true; // close the modal
            return false; // nothing happens
        }
    });

    // set content
    modal.setContent(rodo.innerHTML);

    var checkboxLabel = rodo.getAttribute('data-label'),
        buttonLabel   = rodo.getAttribute('data-btn');

    // add a button that will be replaced with checkbox; the html of the button will become label for the checkbox
    modal.addFooterBtn(checkboxLabel, 'js-replacement', function() {
      //modal.close();
    });

    // add another button
    modal.addFooterBtn(buttonLabel, 'c-btn c-btn--primary tingle-btn--pull-right', function() {
      // here goes some logic
      var confirm = document.getElementById('js-confirm');

      if (confirm.checked) {
        modal.close();
      } else {
        label.classList.add('c-Rodo__Confirmation--Error');
        window.setTimeout(function () {
    	    label.classList.remove('c-Rodo__Confirmation--Error');
        }, 1000);
      }
    });

    modal.open();

    const checkbox = document.createElement('input'),
          label    = document.createElement('label'),
          toBeReplaced = document.querySelector('.js-replacement');

    checkbox.type= 'checkbox';
    checkbox.id = 'js-confirm';
    checkbox.value = '1';
    checkbox.classList.add('c-Rodo__Checkbox');

    label.classList.add('c-Rodo__Confirmation');
    label.innerHTML = toBeReplaced.innerHTML;
    label.insertBefore(checkbox, label.firstChild);

    toBeReplaced.parentNode.replaceChild(label, toBeReplaced);

  }
};

module.exports = modals;
