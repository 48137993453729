/*
  Project: Ps2018
  Author: Krzysztof Malina
*/
/*
var $ = require('jquery');
window.jQuery = window.$ = $; // You expose jQ as a global object




var secondaryNav = require('./secondary-nav.js');
var taps = require('./taps.js');
var clickable = require('./clickable.js');

var detectFlexbox = require('./detectflexbox.js');

var animateTestimonials = require('./testimonials.js');


detectFlexbox();

animateTestimonials();

taps();
clickable();

heights();
*/
var externalLinks = require('./external-links.js');
//var stickToScreen = require('./stick.js');
var googleFonts = require('./googlefonts.js');
var mobileNav = require('./mobilenav.js');
var cookies = require('./cookies.js');
var animateSlides = require('./slides.js');
var masonryLayout = require('./masonry.js')
var heights = require('./heights.js');
var tooltip = require('./tooltip.js');
var filterableSelect = require('./filterable-select.js')
var inputDate = require('./input-date.js')
var tabs = require('./tabs.js')
var scrollTable = require('./scroll-table.js')
var modals = require('./modals.js')
//var up = require('./up-button.js');

scrollTable();
googleFonts();
mobileNav();
heights();
cookies();
externalLinks();
masonryLayout();
tooltip();
animateSlides();
filterableSelect();
inputDate();
tabs();
modals();
//stickToScreen();
//up();
