'use strict';

var tooltip = function () {

  const tippy = require('tippy.js')

  tippy( '.js-tooltip', {
    arrow: true,
    size: 'large',
    duration: 500,
    animation: 'shift-away',
    inertia: true,
    theme: 'isob',
    arrowType: 'round',
  });

};

module.exports = tooltip;
