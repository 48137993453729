'use strict';

var masonryLayout = function () {

  var element = document.getElementsByClassName('js-masonry');

  if(element.length) {
    var Masonry = require('masonry-layout'),
        imagesLoaded = require('imagesloaded');

    imagesLoaded( element[0], function() {
      var msnry = new Masonry( '.js-masonry', {
        itemSelector: '.js-masonry__item',
        //columnWidth: 350,
        columnWidth: '.grid-sizer',
        percentPosition: true
      });
    });
  }

};

module.exports = masonryLayout;
