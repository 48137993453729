'use strict';

var inputDate= function () {
  var Flatpickr = require('flatpickr');
  Flatpickr('.js-input-date', {
    altInput: true,
    altFormat: "F j, Y",
    dateFormat: "d-m-Y"
  });

};

module.exports = inputDate;
