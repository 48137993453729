'use strict';

var $ = require('jquery');
window.jQuery = window.$ = $;
require('jquery-debouncedresize');

var scrollTable = function () {
  var tables = document.querySelectorAll('.u-table');

  if (tables !== null ) {

    var insertIndicator = function(table) {
      var indicator  = document.createElement('div');
      indicator.classList.add('u-table__indicator');
      table.parentNode.insertBefore( indicator, table );
    }

    for(var i=0; i < tables.length; i++) {
      var scrollWidth = tables[i].scrollWidth,
          visibleWidth = tables[i].clientWidth;

          console.log('pelna szerokosc ' + scrollWidth + ', a widoczna ' + visibleWidth);

      if(scrollWidth > visibleWidth) {
        insertIndicator(tables[i]);
      }
    };
    /*
    $(window).on('debouncedresize', function(){
      if(scrollWidth > visibleWidth) {
        var ind = document.querySelector('.u-table__indicator');
        if( ind !== null ) {
          // element exists
        } else {
          insertIndicator(table);
        }

      } else {
        // remove indicator if exists
      }
    });
    */
  }

};

module.exports = scrollTable;
