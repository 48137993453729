'use strict';
import Swiper from 'swiper';

var animateslides = function () {
  var element = document.getElementsByClassName('swiper');
  if(element.length) {

    const slides = new Swiper ( '.swiper', {
      speed: 400,
      loop: true,
      //effect: 'fade',
      autoplay: {
        delay: 3000,
      },
      centeredSlides: true,
      simulateTouch: false,
      pagination: {
          el: '.swiper-pagination',
          type: 'bullets',
          clickable: true
        },
      // Navigation arrows
      navigation: {
        nextEl: '.swiper-button-next',
        prevEl: '.swiper-button-prev',
      },
        // And if we need scrollbar
      scrollbar: false
    });

  }
}

module.exports = animateslides;
